export default [
  {
    key: 'LISTENER',
    value: 'Слушатель',
  },
  {
    key: 'CUSTOMER',
    value: 'Клиент',
  },
  {
    key: 'MANAGER',
    value: 'Менеджер УЦ',
  },
  {
    key: 'ADMIN',
    value: 'Администратор',
  },
  {
    key: 'METHODIST',
    value: 'Методист',
  },
]