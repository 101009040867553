<template>
  <div class="up-error-page">
    <TheHeader v-if="accessToken" />
    <div class="up-error-page__body">
      <main class="up-error-page__main">
        <p class="up-error-page__status">404</p>
        <p class="up-error-page__annotation">Страница не найдена</p>
        <router-link class="el-button el-button--primary" to="/">
          На
          <template v-if="accessToken">главную страницу</template>
          <template v-else>страницу авторизации</template>
        </router-link>
      </main>
    </div>
  </div>
</template>

<script>
import TheHeader from "@/components/layout/TheHeader";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "ErrorPage",
  components: { TheHeader },
  computed: {
    ...mapGetters({ accessToken: "getAccessToken" }),
  },
  methods: {
    ...mapActions(["setNavigationText"]),
  },
  created() {
    this.setNavigationText("");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/error-page.scss";
</style>