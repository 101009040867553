export default function beforeEnter(store) {
  return function (to, from, next) {
    const isLoggedIn = store.getters.getAccessToken;

    if (!isLoggedIn) {
      next({ path: '/login' });
      return;
    }

    const userRole = store.getters.getUserRole.toLowerCase();

    // Если будут еще страницы, которые нужно сюда прописать хардкодом, то 
    // нужно вынести проверку в функцию
    
    // Если будут просто статичные страницы, то нужно переделать структуру
    // и проверять не от /index страницы, а создать каталог по типу: lk...
    if (to.path.startsWith(`/${userRole}`) || to.path.includes('change-password')) {
      next();
      return;
    }

    switch (userRole) {
      case "listener":
        next({ path: `/${userRole}` });
        break;
      case "customer":
      case "manager":
      case "admin":
        next({ path: `/${userRole}/requests` });
        break;
      case "methodist":
        next({ path: `/${userRole}/courses` });
        break;
      default:
        break;
    }
  }
}
