import Vue from 'vue'
import Vuex from 'vuex'

import navigation from "./modules/navigation"
import auth from "./modules/auth"
import documents from "./modules/documents"
import request from "./modules/request"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    navigation,
    auth,
    documents,
    request,
  }
})
