import dict from '@/dict/declension';

/**
 * String Helper Class
 */
export class Str {
  /**
   *
   * @param {number | string} num
   * @param {string} word
   * @return {string}
   */
  static declension(num, word) {
    const exclusions = ['11', '12', '13', '14'];

    if (typeof num === 'number') {
      num = num.toString();
    }

    const lastTwoChars = num.substring(num.length - 2);
    const lastChar = +num.charAt(num.length - 1);

    const words = dict.find((d) => d.includes(word));

    if (!words) return;

    if (exclusions.includes(lastTwoChars)) {
      return words[2];
    }

    switch (lastChar) {
      case 1:
        return words[0];
      case 2:
      case 3:
      case 4:
        return words[1];
      default:
        return words[2];
    }
  }

  /**
   * Check if string is empty
   * @param {string} str
   * @return {boolean}
   */
  static empty(str) {
    return str ? str.trim() === '' : true;
  }
  
  /**
   * Check if string is email
   * @param {string} str
   * @return {boolean}
   */
  static email(str) {
    // eslint-disable-next-line max-len
    const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;
    return regex.test(str);
  }

  /**
   * Check if string is email
   * @param {string} str
   * @return {string} formatted phone
   */
  static phoneFormatted(str) {
    return str.replace(/[^+\d]/g, "");
  }

  /**
   * Check if string is email
   * @param {string} str
   * @return {string} formatted string to only digits
   */
  static digitsFormatted(str) {
    return str.replace(/[^\d]/g, "");
  }

  /**
   *
   * @param {string} str
   * @return {boolean}
   */
   static phoneNotCorrect(str) {
    return /[^+()-\d ]/.test(str);
  }
}

export default Str;
