export default {
  state: {
    navigationText: "",
  },
  getters: {
    getNavigationText(state) {
      return state.navigationText;
    }
  },
  mutations: {
    setNavigationText(state, text) {
      state.navigationText = text;
    }
  },
  actions: {
    setNavigationText({ commit }, text) {
      commit('setNavigationText', text);
    }
  }
}