<template>
  <nav class="up-nav">
    <template v-for="(item, i) in menuItemsByRole">
      <router-link class="up-nav__item" :to="item.route" :key="i">
        <i :class="item.icon" /> {{ item.name }}
      </router-link>
      <router-link
        v-if="item.sub"
        class="up-nav__item up-nav__item--sub"
        :to="item.sub.route"
        :key="`${i}-subitem`"
      >
        <i :class="item.sub.icon" /> {{ item.sub.name }}
      </router-link>
    </template>
    <button class="up-nav__item up-nav__exit-btn" @click="exitHandler">
      Выход
    </button>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AppNavItems",
  data: () => ({
    menuItems: [
        // Listener
      {
        route: "/listener",
        name: "Мои курсы",
        icon: "el-icon-document",
        role: "listener",
      },
        // Customer
      {
        route: "/customer/requests",
        name: "Мои заявки",
        icon: "el-icon-document",
        role: "customer",
      },
      {
        route: "/customer/courses",
        name: "Все курсы",
        icon: "el-icon-document-copy",
        role: "customer",
      },
      // Manager
      {
        route: "/manager/requests",
        name: "Мои заявки",
        icon: "el-icon-document",
        role: "manager",
        sub: {
          route: "/manager/requests/new",
          name: "новая заявка",
          icon: "el-icon-document-add",
        },
      },
      {
        route: "/manager/customers",
        name: "Мои клиенты",
        icon: "el-icon-user",
        role: "manager",
        sub: {
          route: "/manager/customers/new",
          name: "новый клиент",
          icon: "el-icon-coordinate",
        },
      },
      // Admin
      {
        route: "/admin/educational-center",
        name: "Учебные центры",
        icon: "el-icon-office-building",
        role: "admin",
        sub: {
          route: "/admin/educational-center/new",
          name: "новый УЦ",
          icon: "el-icon-school",
        },
      },
      {
        route: "/admin/requests",
        name: "Все заявки",
        icon: "el-icon-document",
        role: "admin",
      },
      {
        route: "/admin/users",
        name: "Пользователи и роли",
        icon: "el-icon-user",
        role: "admin",
      },
      {
        route: "/admin/settings",
        name: "Настройки",
        icon: "el-icon-setting",
        role: "admin",
      },
      // Methodist
      {
        route: "/methodist/courses",
        name: "Курсы",
        icon: "el-icon-document",
        role: "methodist",
        sub: {
          route: "/methodist/courses/new",
          name: "новый курс",
          icon: "el-icon-document-add",
        },
      },
      {
        route: "/methodist/categories",
        name: "Категории",
        icon: "el-icon-document-copy",
        role: "methodist",
      },
    ],
  }),
  methods: {
    ...mapActions(["unsetAuth", "setUserRole"]),
    exitHandler() {
      this.unsetAuth();

      window.location.reload();
    },
  },
  computed: {
    ...mapGetters({ userRole: "getUserRole" }),
    menuItemsByRole() {
      return [...this.menuItems].filter(
        (i) => i.role === this.userRole.toLowerCase()
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/app-nav-items.scss";
</style>