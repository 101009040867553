const lookup = {
  d: {
    key: 'day',
    value: '2-digit',
  },
  D: {
    key: 'weekday',
    value: 'short',
  },
  j: {
    key: 'day',
    value: 'numeric',
  },
  l: {
    key: 'weekday',
    value: 'long',
  },
  N: (date) => date.getDay().toString(),
  S: () => {
    console.warn('"S" key isn\'t implemented');
    return '';
  },
  w: () => {
    console.warn('"w" key isn\'t implemented');
    return '';
  },
  z: () => {
    console.warn('"z" key isn\'t implemented');
    return '';
  },
  W: () => {
    console.warn('"W" key isn\'t implemented');
    return '';
  },
  F: {
    key: 'month',
    value: 'long',
  },
  m: {
    key: 'month',
    value: '2-digit',
  },
  M: {
    key: 'month',
    value: 'short',
  },
  n: {
    key: 'month',
    value: 'numeric',
  },
  t: () => {
    console.warn('"t" key isn\'t implemented');
    return '';
  },
  L: () => {
    console.warn('"L" key isn\'t implemented');
    return '';
  },
  o: {
    key: 'year',
    value: 'numeric',
  },
  Y: {
    key: 'year',
    value: 'numeric',
  },
  y: {
    key: 'year',
    value: '2-digit',
  },
  a: (date, timeZone) => {
    return date
      .toLocaleString('en', { hour12: true, hour: 'numeric', timeZone })
      .slice(-2)
      .toLowerCase();
  },
  A: (date, timeZone) => {
    return date
      .toLocaleString('en', { hour12: true, hour: 'numeric', timeZone })
      .slice(-2)
      .toUpperCase();
  },
  B: () => {
    console.warn('"B" key isn\'t implemented');
    return '';
  },
  g: (date, timeZone) => {
    return date
      .toLocaleString('en', { hour12: true, hour: 'numeric', timeZone })
      .replace(/ [AP]M/g, '');
  },
  G: (date, timeZone) => {
    return date
      .toLocaleString('ru', { hour: '2-digit', timeZone })
      .replace(/^0/g, '');
  },
  h: (date, timeZone) => {
    return date
      .toLocaleString('en', { hour12: true, hour: '2-digit', timeZone })
      .replace(/ [AP]M/g, '');
  },
  H: {
    key: 'hour',
    value: '2-digit',
  },
  i: (date) => {
    return date
      .toLocaleString('ru', { hour12: false, minute: '2-digit' })
      .padStart(2, '0');
  },
  s: (date) => date.toLocaleTimeString('ru').slice(-2),
  u: () => {
    console.warn('"u" key isn\'t implemented');
    return '';
  },
  v: (date) => date.getMilliseconds(),
  e: () => {
    console.warn('"e" key isn\'t implemented');
    return '';
  },
  I: () => {
    console.warn('"I" key isn\'t implemented');
    return '';
  },
  O: () => {
    console.warn('"O" key isn\'t implemented');
    return '';
  },
  P: () => {
    console.warn('"P" key isn\'t implemented');
    return '';
  },
  T: () => {
    console.warn('"T" key isn\'t implemented');
    return '';
  },
  Z: () => {
    console.warn('"Z" key isn\'t implemented');
    return '';
  },
  c: (date) => date.toISOString(),
  r: () => {
    console.warn('"r" key isn\'t implemented');
    return '';
  },
  U: (date) => date.valueOf(),
};

/**
 * DateTime Helper Class
 */
export class DateTime {
  /**
   * Format given date by format pattern
   *
   * @param {Date} date
   * @param {string} format according to https://www.php.net/manual/ru/datetime.format.php
   * @param {string} timeZone existing time zone code
   */
  static format(date, format = 'd.m.Y, H:i:s', timeZone = 'Europe/Moscow') {
    const fArr = format.split('');

    return fArr.reduce((str, char) => {
      if (!lookup[char]) {
        return str + char;
      } else if (typeof lookup[char] === 'function') {
        return str + lookup[char](date, timeZone);
      }

      const { key, value } = lookup[char];

      return str + date.toLocaleString('ru', { [key]: [value], timeZone });
    }, '');
  }
}

export default DateTime;
