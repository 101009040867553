import Vue from 'vue';

import DateTime from '@/helpers/DateTime';
import Str from '@/helpers/Str';

Vue.filter('ruDate', (date, format = 'd.m.Y, H:i:s') => {
  return DateTime.format(new Date(date), format);
});

Vue.filter('wordDeclension', (num, word, withNum = true) => {
  const prefixText = withNum ? `${num} ` : '';

  return `${prefixText}${Str.declension(num, word)}`;
});
