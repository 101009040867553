import Vue from 'vue'

export default {
  state: {
    accountData: {},
  },
  getters: {
    getAccessToken() {
      return Vue.$cookies.get('access');
    },
    getUserRole() {
      return Vue.$cookies.get('user-role');
    },
    getAccountData(state) {
      return state.accountData;
    }
  },
  mutations: {
    unsetAuth(state) {
      state.accountData = {};
      Vue.$cookies.remove('access');
      Vue.$cookies.remove('user-role');
    },
    setUserRole(_, role) {
      Vue.$cookies.set('user-role', role, '30d');
    },
    setAccountData(state, data) {
      state.accountData = data;
    }
  },
  actions: {
    unsetAuth({ commit }) {
      commit('unsetAuth');
    },
    setUserRole({ commit }, role) {
      commit('setUserRole', role);
    },
    async fetchAccountData({ commit }) {
      try {
        const { data: accData } = await Vue.prototype.$axios.get('/auth/me');

        commit('setAccountData', accData);

        return true;
      } catch (e) {
        commit('unsetAuth');

        return false;
      }
    }
  }
}