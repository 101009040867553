<template>
  <div class="up-main-page">
    <NprogressContainer />
    <TheHeader :show-nav-items="showNavItems" />
    <div class="up-main-page__body">
      <aside v-if="showNavItems" class="up-main-page__aside">
        <AppNavItems />
      </aside>
      <main class="up-main-page__main">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import TheHeader from "@/components/layout/TheHeader";
import AppNavItems from "@/components/AppNavItems";
import NprogressContainer from "vue-nprogress/src/NprogressContainer";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "HomePage",
  components: { TheHeader, AppNavItems, NprogressContainer },
  computed: {
    ...mapGetters({ userRole: "getUserRole" }),
    showNavItems() {
      return true; // this.userRole !== "LISTENER";
    },
  },
  methods: {
    ...mapActions(["fetchAccountData", "fetchDocuments"]),
  },
  async created() {
    const isSuccess = await this.fetchAccountData();

    if (isSuccess) {
      await this.fetchDocuments();
      return;
    }

    window.location.reload();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/index.scss";
</style>