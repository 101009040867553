export default {
  state: {
    requests: [],
  },
  getters: {
    getActiveRequests(state) {
      return state.requests;
    }
  },
  mutations: {
    addRequest(state, req) {
      state.requests.push(req);
    },
    cancelRequest(state) {
      state.requests.forEach((r) => r.cancel());
      state.requests = [];
    },
  },
  actions: {
    addRequest({ commit }, req) {
      commit("addRequest", req);
    },
    cancelRequest({ commit }) {
      commit("cancelRequest");
    }
  }
}