import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

import Index from '@/views/index.vue'
import ErrorPage from '@/views/error-page.vue'

// Guards
import authenticated from './guards/authenticated';
import notAuthenticated from './guards/notAuthenticated';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    beforeEnter: authenticated(store),
    children: [
      // Static pages needed authenticate
      {
        path: 'change-password',
        component: () => import(/* webpackChunkName: "change-password" */ '@/views/change-password.vue'),
      },
      // Listener
      {
        path: 'listener',
        component: () => import(/* webpackChunkName: "listener" */ '@/views/listener/index.vue'),
      },
      {
        path: 'listener/course/:id',
        component: () => import(/* webpackChunkName: "course" */ '@/views/listener/course/id/index.vue'),
      },
      {
        path: 'listener/course/:id/lesson/:lid',
        component: () => import(/* webpackChunkName: "course-lessons" */ '@/views/listener/course/id/lesson/_lid.vue'),
      },
      {
        path: 'listener/course/:id/success',
        component: () => import(/* webpackChunkName: "course-success" */ '@/views/listener/course/id/success.vue'),
      },
      // Customer
      {
        path: 'customer/courses',
        component: () => import(/* webpackChunkName: "customer-courses" */ '@/views/customer/courses.vue'),
      },
      {
        path: 'customer/requests',
        component: () => import(/* webpackChunkName: "customer-requests" */ '@/views/customer/requests/index.vue'),
      },
      {
        path: 'customer/requests/:id',
        component: () => import(/* webpackChunkName: "customer-request" */ '@/views/customer/requests/_id.vue'),
      },
      // Manager
      {
        path: 'manager/requests',
        component: () => import(/* webpackChunkName: "manager-request" */ '@/views/manager/requests/index.vue'),
      },
      {
        path: 'manager/requests/new',
        component: () => import(/* webpackChunkName: "manager-new-request" */ '@/views/manager/requests/new.vue'),
      },
      {
        path: 'manager/requests/:id',
        component: () => import(/* webpackChunkName: "show-manager-request" */ '@/views/manager/requests/_id.vue'),
      },
      {
        path: 'manager/customers',
        component: () => import(/* webpackChunkName: "manager-customers" */ '@/views/manager/customers/index.vue'),
      },
      {
        path: 'manager/customers/new',
        component: () => import(/* webpackChunkName: "new-manager-customer" */ '@/views/manager/customers/new.vue'),
      },
      // Admin
      {
        path: 'admin/educational-center',
        component: () => import(/* webpackChunkName: "educational-center" */ '@/views/admin/educational-center/index.vue'),
      },
      {
        path: 'admin/educational-center/new',
        component: () => import(/* webpackChunkName: "new-educational-center" */ '@/views/admin/educational-center/new.vue'),
      },
      {
        path: 'admin/educational-center/:id',
        component: () => import(/* webpackChunkName: "edit-educational-center" */ '@/views/admin/educational-center/_id.vue'),
      },
      {
        path: 'admin/requests',
        component: () => import(/* webpackChunkName: "admin-requests" */ '@/views/admin/requests.vue'),
      },
      {
        path: 'admin/users',
        component: () => import(/* webpackChunkName: "users" */ '@/views/admin/users.vue'),
      },
      {
        path: 'admin/settings',
        component: () => import(/* webpackChunkName: "settings" */ '@/views/admin/settings.vue'),
      },
      // Methodist
      {
        path: 'methodist/categories',
        component: () => import(/* webpackChunkName: "methodist-categories" */ '@/views/methodist/categories.vue'),
      },
      {
        path: 'methodist/courses',
        component: () => import(/* webpackChunkName: "methodist-courses" */ '@/views/methodist/courses/index.vue'),
      },
      {
        path: 'methodist/courses/new',
        component: () => import(/* webpackChunkName: "methodist-new-course" */ '@/views/methodist/courses/new.vue'),
      },
      {
        path: 'methodist/courses/:id',
        component: () => import(/* webpackChunkName: "methodist-show-course" */ '@/views/methodist/courses/_id/index.vue'),
      },
      {
        path: 'methodist/courses/:id/preview',
        component: () => import(/* webpackChunkName: "methodist-preview-lecture" */ '@/views/methodist/courses/_id/preview.vue'),
      },
      {
        path: 'methodist/courses/:id/lecture/add',
        component: () => import(/* webpackChunkName: "methodist-add-lecture" */ '@/views/methodist/courses/_id/lecture/add.vue'),
      },
      {
        path: 'methodist/courses/:id/lecture/:lid',
        component: () => import(/* webpackChunkName: "methodist-edit-lecture" */ '@/views/methodist/courses/_id/lecture/_lid.vue'),
      },
      {
        path: 'methodist/courses/:id/test/add',
        component: () => import(/* webpackChunkName: "methodist-add-test" */ '@/views/methodist/courses/_id/test/add.vue'),
      },
      {
        path: 'methodist/courses/:id/test/:lid',
        component: () => import(/* webpackChunkName: "methodist-edit-test" */ '@/views/methodist/courses/_id/test/_lid.vue'),
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/views/login.vue'),
    beforeEnter: notAuthenticated(store),
  },
  { path: '*', component: ErrorPage }
]

const routerInstance = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

routerInstance.beforeEach((_, from, next) => {
  store.dispatch('cancelRequest');

  setTimeout(() => {
    next();
  }, 100);
});

export default routerInstance;