import Vue from 'vue';

export default {
  state: {
    privacy: {
      name: 'Политика конфиденциальности',
      hasFile: false,
    },
    user_agreement: {
      name: 'Пользовательское соглашение',
      hasFile: false,
    },
  },
  getters: {
    getDocuments(state) {
      return { privacy: state.privacy, user_agreement: state.user_agreement };
    }
  },
  mutations: {
    setDocument(state, key) {
      state[key].hasFile = true;
      state[key].downloadLink = `${process.env.VUE_APP_SERVER_URL}/admin/attachment/${key}`;
    },
  },
  actions: {
    async fetchDocuments({ commit }) {
      try {
        await Vue.prototype.$axios.get('/admin/attachment/privacy');

        commit('setDocument', 'privacy');
      } catch (e) {
        // 
      }

      try {
        await Vue.prototype.$axios.get('/admin/attachment/user_agreement');

        commit('setDocument', 'user_agreement');
      } catch (e) {
        // 
      }
    },
  }
}