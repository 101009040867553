<template>
  <header class="up-header">
    <div
      class="up-header__burger"
      role="button"
      aria-label="Открыть меню"
      @click="drawerVisible = true"
    >
      <span></span><span></span><span></span>
    </div>
    <el-select
      v-if="hasRolesSelector"
      v-model="userRoleModel"
      @change="changeRole"
      class="up-header__roles-selector"
    >
      <el-option
        v-for="r in accountData.roles"
        :value="r.name"
        :key="r.name"
        :label="roleTranslated(r.name)"
      />
    </el-select>
<!--    <p class="up-header__nav-text">{{ navigationText }}</p>-->
    <img src="/logo.svg" />
    <el-dropdown
      class="up-header__name-wrapper"
      @command="dropdownChangeHandler"
      trigger="click"
    >
      <span class="up-header__name">{{ accountData.name }}</span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="change-pwd">Смена пароля</el-dropdown-item>
        <el-dropdown-item command="logout">Выход</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-drawer
      :title="`Здравствуйте, ${accountData.name}!`"
      :visible.sync="drawerVisible"
      direction="ltr"
    >
      <p v-if="userRoleTranslated" class="up-header__drawer-text">
        Вы вошли под ролью: {{ userRoleTranslated }}
      </p>
      <el-select
        v-if="hasRolesSelector"
        v-model="userRoleModel"
        @change="changeRole"
      >
        <el-option
          v-for="r in accountData.roles"
          :value="r.name"
          :key="`${r.name}-mob`"
          :label="roleTranslated(r.name)"
        />
      </el-select>
      <AppNavItems v-if="showNavItems" />
    </el-drawer>
  </header>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import userRoles from "@/enums/userRoles";

import AppNavItems from "@/components/AppNavItems";

export default {
  name: "TheHeader",
  components: { AppNavItems },
  props: {
    showNavItems: Boolean,
  },
  data: () => ({
    drawerVisible: false,
    userRoles: [...userRoles],
    userRoleModel: "",
  }),
  computed: {
    ...mapGetters({
      navigationText: "getNavigationText",
      userRole: "getUserRole",
      accountData: "getAccountData",
    }),
    userRoleTranslated() {
      const role = this.userRoles.find((r) => r.key === this.userRole);
      return role ? role.value : "";
    },
    hasRolesSelector() {
      return this.accountData.roles && this.accountData.roles.length > 1;
    },
  },
  methods: {
    ...mapActions(["unsetAuth", "setUserRole"]),
    logoutHandler() {
      this.unsetAuth();

      window.location.reload();
    },
    dropdownChangeHandler(command) {
      if (command === "logout") {
        this.logoutHandler();
        return;
      }

      this.$router.push("/change-password");
    },
    changeRole() {
      this.setUserRole(this.userRoleModel);

      window.location.reload();
    },
    roleTranslated(role) {
      const r = this.userRoles.find((r) => r.key === role);
      return r ? r.value : "";
    },
  },
  mounted() {
    this.userRoleModel = this.userRole;
  },
  watch: {
    $route() {
      this.drawerVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/layout/the-header.scss";
</style>