import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './plugins/filters'
import axiosInstance from './plugins/axios'
import VueCookies from 'vue-cookies'
import NProgress from 'vue-nprogress'

// Global styles
import '@/assets/styles/index.scss'

Vue.config.productionTip = false

Vue.use(VueCookies)
Vue.use(NProgress, { router: false })

const nprogress = new NProgress()
const token = store.getters.getAccessToken;

if (token) {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

Vue.prototype.$axios = axiosInstance

new Vue({
  router,
  store,
  nprogress,
  render: h => h(App)
}).$mount('#app')
