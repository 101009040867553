import Vue from 'vue'
import {
  Button,
  Form,
  FormItem,
  Input,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Tag,
  Dialog,
  Radio,
  Pagination,
  Select,
  Option,
  OptionGroup,
  DatePicker,
  Message,
  Collapse,
  CollapseItem,
  Drawer,
  Upload,
  Loading,
  Alert,
  Checkbox,
  Breadcrumb,
  BreadcrumbItem,
  Tabs,
  TabPane,
} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/ru-RU'
import locale from 'element-ui/lib/locale'

locale.use(lang)

Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Tag);
Vue.use(Dialog);
Vue.use(Radio);
Vue.use(Pagination);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(DatePicker);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Drawer);
Vue.use(Upload);
Vue.use(Alert);
Vue.use(Checkbox);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Tabs);
Vue.use(TabPane);

Vue.prototype.$message = Message;

Vue.use(Loading.directive);