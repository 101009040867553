export default function beforeEnter(store) {
  return function (to, from, next) {
    const isLoggedIn = store.getters.getAccessToken;

    if (!isLoggedIn) {
      next();
      return;
    }

    next({ path: '/' });
  }
}
