import axios from 'axios'
import store from '../store/index'

function addCancelMethod(request) {
  const axiosSource = axios.CancelToken.source();

  request.cancelToken = axiosSource.token;
  store.dispatch("addRequest", { ...request, cancel: axiosSource.cancel });

  return request;
}

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL,
});

axiosInstance.interceptors.request.use(addCancelMethod);

export default axiosInstance;
